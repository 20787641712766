html {
  font-size: calc(15px + 0.390625vw);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-image: url("assets/images/texture.jpg");
  font-family: "Saira Semi Condensed", sans-serif;
}

a {
  color: var(--primary-color);
}

h1 {
  font-family: "Saira Semi Condensed", sans-serif;
  font-size: 5rem;
  color: var(--primary-color);
}

h3 {
  color: var(--primary-color);
  font-size: 1.5rem;
}

h4 {
  color: var(--primary-color);
  font-style: italic;
  margin-top: 1rem;
}

section {
  margin-left: 3rem;
  margin-right: 3rem;
}

p {
  color: var(--primary-color);
  font-size: 0.65rem;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (min-width: 176px) and (max-width: 600px) {
  section {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

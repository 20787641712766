.nav-items { 
  border: 0.3rem solid var(--primary-color);
  color: var(--primary-color);
  font-family: "Saira Semi Condensed";
  font-size: clamp(.8rem, .9rem, 1.5rem);
  position: absolute;
  text-align: right;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
}

.nav-item { 
   
}

.nav-item:hover {
   cursor: pointer;
   background-color: var(--primary-color);
   color: var(--secundary-color);
}

.active-item{
  background-color: var(--primary-color);
  color: var(--secundary-color);
}

.nav-items-active {
  display: block;
}

.nav-items-inactive {
  z-index: 1;
  display: none;
}

.barnav {
  display: flex;
  font-size: 0.9rem;
  font-weight: bold;
  justify-content: space-between;
  border: 0.3rem solid var(--primary-color);
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: -webkit-fill-available;
}

.barnav-item {
  font-family: "Saira Semi Condensed";
}

@media only screen and (min-width: 176px) and (max-width: 600px) {
  .nav-items {
    background-color: var(--secundary-color);
    display: none;
    font-size: clamp(1rem, 2vw + 1rem, 1.5rem);
    z-index: 1;
    position: relative;
  }
  .nav-item {
    padding: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .nav-item {
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .nav-items {
    display: flex;
    position: relative;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .nav-item-label {
    display: block;
  }
  .nav-item-icon svg {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

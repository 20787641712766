.menu-mobile {
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu-mobile-logo {
  padding: 1rem;
}

.menu-mobile-logo > img {
  width: 4rem;
}

.menu-mobile-icon {
  border: 1px rgb(30, 30, 30) solid;
  padding: 10px;
  display: inline-block;
  border-width: 0px;
}

.menu-mobile-icon > img {
  width: 2rem;
}

.menu-mobile-title {
  display: inline-block;
  font-weight: bolder;
  font-family: "Saira Semi Condensed", sans-serif;
  font-size: 2rem;
  margin: auto;
  margin-left: 1rem;
}

nav {
  font-size: 2rem;
  font-weight: bold;
  justify-content: space-between;
  width: 100%;
}


.nav-filled {
  background-color: var(--primary-bg-color);
  box-shadow: 0px 3px 8px var(--border-color);
}



.nav-item-label {
  display: none;
}

.nav-item-icon svg {
  max-width: fit-content;
  margin-left: 1rem;
}

.nav-icon {
}

.nav-icon-img {
  width: 2.5rem;
  border-radius: 50%;
  animation: 0.5s brightness 1;
}

.nav-icon-img-animated {
  animation: 0.5s brightness 1;
}

.col {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: left;
}

.row {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: 100%;
}




.band-logo {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}





@keyframes brightness {
  0% {
    filter: brightness(1);

    -webkit-filter: brightness(1);
  }
  50% {
    filter: brightness(1.7);

    -webkit-filter: brightness(1.7);
  }
  100% {
    filter: brightness(1);

    -webkit-filter: brightness(1);
  }
}

.nav-link-active {
  color: var(--primary-color);
  border-bottom: 0.2rem var(--primary-color) solid;
  padding-bottom: 0.4rem;
}

.nav-link {
  color: var(--second-bg-color);
}

@media only screen and (min-width: 176px) and (max-width: 600px) {

  .banner{
    display: none;
  }
  .unit{
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .nav {
    padding: 1rem;
  }
   
  .menu-mobile{
    display: none;
  }
  .menu-desktop{
    display: block;
  }
  .menu-desktop{ 
    margin: .5rem 3rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .nav-item-label {
    display: block;
  }
  .nav-item-icon svg {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .nav {
    position: fixed;
    top: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.header-bar {
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    font-size: .7rem;
    font-family: "Saira Semi Condensed";
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 1rem;
    border-top: var(--grijs_15) 0.1rem solid;
    border-bottom: var(--grijs_15) 0.1rem solid;
  }



@media only screen and (min-width: 176px) and (max-width: 600px) {
  .header-bar{
    margin-left: 1rem;
    margin-right: 1rem;
  }
   
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
   
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

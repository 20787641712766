:root {
/*
	$primary: #3579e9;
	$secundary: #333;
	$tertiary: #fff;
	$success: green;
	$alert: red;
	$light: #ccc;
	$superlight: #fff;
	$dark: black;
*/

--primary-color: rgb(71, 71, 71);
--secundary-color: white;
--o: rgb(197, 65, 65);
--i: #caf42b;
--color-secundary: rgb(62, 115, 229);

--grijs_03: #efefef;
--grijs_15: #dadce0;

}

.unit {
    background-color: var(--primary-color);
    color: var(--secundary-color);
    font-size: 1.5rem;
    padding: 1rem; 
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 176px) and (max-width: 600px) {
 
    .unit{
      display: none;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
     
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
     
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
     
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
  